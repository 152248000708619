.slate-editor-toolbar {
  margin-bottom: 12px;
  background: #f8f8f8;
}

.slate-content-editor {
  font-size: 14pt;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.create-dialog-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
