.container {
  background: #f0f0f0;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.content {
  position: relative;
  width: 100%;
  height: 40px;
}

.content > div {
  position: absolute;
  width: 100%;
  height: 40px;
  transform-origin: 50% 50% 0px;
  padding-top: 12px;
  padding-right: 12px;
  border-radius: 5px;
  color: black;
  line-height: 20px;
  padding-left: 32px;
  font-size: 14.5px;
  background: lightgray;
  letter-spacing: 2px;
  touch-action: none;
}
