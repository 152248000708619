/* Resetting default margins on body*/
body {
  margin: 0;
  padding: 0;
}

/*Adding minimum height to the card so the Toolbar popups have enough space */
.MuiCardContent-root {
  min-height: 400px;
}
